import { useMutation } from "@vue/apollo-composable";
import {
  UpdateAssetManagementActionPlanDocument,
  type AssetManagementActionPlanStatus,
  type UpdateAssetManagementActionPlanMutationVariables,
} from "~/graphql/generated/graphql";

export const useUpdateActionPlanMutation = (id: string, status?: AssetManagementActionPlanStatus, title?: string) => {
  const variables: UpdateAssetManagementActionPlanMutationVariables = {
    input: { id, status, title },
  };

  return useMutation(UpdateAssetManagementActionPlanDocument, { variables });
};
