import { useMutation } from "@vue/apollo-composable";
import { UpdateAssetManagementActionPlanPageDocument, type UpdateAssetManagementActionPlanPageMutationVariables } from "~/graphql/generated/graphql";

export const useUpdateActionPlanPageMutation = (groupId: string, title: string, content: string) => {
  const variables: UpdateAssetManagementActionPlanPageMutationVariables = {
    input: { groupId, title, content },
  };

  return useMutation(UpdateAssetManagementActionPlanPageDocument, { variables });
};
