// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".changes_h-8Pa{display:flex;gap:.5rem}.changeContainer_gG64H{background-color:var(--color-supportive-3-50);border:1px solid var(--color-supportive-3-200);border-radius:var(--border-radius-medium);color:var(--color-black-900);margin:.5rem 0;padding:1rem;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"changes": "changes_h-8Pa",
	"changeContainer": "changeContainer_gG64H"
};
module.exports = ___CSS_LOADER_EXPORT___;
