// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menuItem_uqaKl{cursor:pointer;padding:.5rem 1rem}.menuItem_uqaKl:hover,.selected_64boi{background-color:var(--color-supportive-3-100)}.selected_64boi{border-radius:var(--border-radius-large)}.pullRight_\\+YXoT{align-items:center;display:flex;flex-direction:row;justify-content:flex-end;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuItem": "menuItem_uqaKl",
	"selected": "selected_64boi",
	"pullRight": "pullRight_+YXoT"
};
module.exports = ___CSS_LOADER_EXPORT___;
