import { useMutation } from "@vue/apollo-composable";
import {
  AssetManagementActionPlansDocument,
  CreateAssetManagementActionPlanDocument,
  type CreateAssetManagementActionPlanMutationVariables,
} from "~/graphql/generated/graphql";
import { Operator } from "~/helpers/apollo/apollo-utility-helpers";

export const useCreateActionPlanMutation = (propertyId: string, title: string, fromTemplateId?: string) => {
  const variables: CreateAssetManagementActionPlanMutationVariables = {
    propertyId: propertyId,
    title: title,
    fromTemplateId: fromTemplateId,
  };

  const queryVariables = { filters: { groups: [{ matches: [{ property_id: [Operator.Equals, propertyId] }] }] } };

  return useMutation(CreateAssetManagementActionPlanDocument, () => ({
    variables,
    update: (cache, { data }) => {
      let plans = cache.readQuery({
        query: AssetManagementActionPlansDocument,
        variables: queryVariables,
      });

      if (plans && data) {
        cache.writeQuery({
          query: AssetManagementActionPlansDocument,
          data: {
            assetManagementActionPlans: [...plans.assetManagementActionPlans, data.createAssetManagementActionPlan],
          },
          variables: queryVariables,
        });
      }
    },
  }));
};
