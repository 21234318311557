import { useQuery } from "@vue/apollo-composable";
import { Operator } from "~/helpers/apollo/apollo-utility-helpers";
import { AssetManagementActionPlansDocument } from "~/graphql/generated/graphql";
export var useActionPlansQuery = function useActionPlansQuery(propertyId) {
  var _useQueryFilters = useQueryFilters(),
    queryFilters = _useQueryFilters.value,
    setQueryFilters = _useQueryFilters.setValue;
  setQueryFilters("filters", function () {
    return {
      matches: [{
        property_id: [Operator.Equals, propertyId]
      }]
    };
  });
  var query = useQuery(AssetManagementActionPlansDocument, {
    filters: queryFilters.value.filters
  });
  var refetchActionPlans = query.refetch;
  var actionPlansloading = computed(function () {
    return query.loading.value;
  });
  var actionPlans = computed(function () {
    var _query$result$value$a, _query$result$value;
    return (_query$result$value$a = (_query$result$value = query.result.value) === null || _query$result$value === void 0 ? void 0 : _query$result$value.assetManagementActionPlans) !== null && _query$result$value$a !== void 0 ? _query$result$value$a : [];
  });
  return {
    actionPlans: actionPlans,
    actionPlansloading: actionPlansloading,
    refetchActionPlans: refetchActionPlans,
    query: query
  };
};